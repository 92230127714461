import { default as abuseReportsGiRK7MO89oMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93HfG3uvosdQMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexScec19txMjMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/index.vue?macro=true";
import { default as moderationbsolzpYOtRMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/moderation.vue?macro=true";
import { default as pendingBansWeIOmAoPHbMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/pendingBans.vue?macro=true";
import { default as profilesgPeiZIJd5yMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/profiles.vue?macro=true";
import { default as expensesJ87p71dGnpMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexgXWYDN0NdVMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewFOiTMeGOABMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingSM3PkdshcaMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingIuAQX6R6suMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/translations/missing.vue?macro=true";
import { default as usersLYJ3OMqXgqMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/users.vue?macro=true";
import { default as apickABJJq9IAMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/api.vue?macro=true";
import { default as _91slug_93fqkoyYb5tJMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/blog/[slug].vue?macro=true";
import { default as indexkNIzVtewZcMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93WC6r9wo3ohMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93M0uXsvK6vEMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminus84JKX0qSMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/census/admin.vue?macro=true";
import { default as indexMlD2KfSZXpMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/census/index.vue?macro=true";
import { default as contacth2DSnJRD9fMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/contact.vue?macro=true";
import { default as designFMTxUC70WIMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/design.vue?macro=true";
import { default as englishC4czNVTT71Meta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/english.vue?macro=true";
import { default as faqorTuxLzNRrMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/faq.vue?macro=true";
import { default as inclusive9vCgD7dDqPMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/inclusive.vue?macro=true";
import { default as indexAcP3nBQ6teMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/index.vue?macro=true";
import { default as license5GqWN6ekaVMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/license.vue?macro=true";
import { default as academickEhI8uUteeMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/links/academic.vue?macro=true";
import { default as indexAa87AoYbL7Meta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/links/index.vue?macro=true";
import { default as mediaHjxkRvEL6EMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/links/media.vue?macro=true";
import { default as translinguisticscDwy1cUdoxMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/links/translinguistics.vue?macro=true";
import { default as zinehqvxREabYfMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/links/zine.vue?macro=true";
import { default as namesqeMbxBvZ0GMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/names.vue?macro=true";
import { default as indexR5ZXqoDDByMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/nouns/index.vue?macro=true";
import { default as templatesoWlIHmPAm7Meta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/nouns/templates.vue?macro=true";
import { default as peoplehAgB9JRejiMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/people.vue?macro=true";
import { default as privacyM1GDZ2OxBPMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/privacy.vue?macro=true";
import { default as _91username_93Ny77RPWno4Meta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93F5wQz8waz4Meta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/profile/card-[username].vue?macro=true";
import { default as editoroIqc621UzXMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/profile/editor.vue?macro=true";
import { default as anyJH1Akfv5GAMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/any.vue?macro=true";
import { default as askTRH3VoaE0jMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/ask.vue?macro=true";
import { default as avoidingCqQePN5OUYMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/avoiding.vue?macro=true";
import { default as indexAcj7S13kfqMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/index.vue?macro=true";
import { default as mirrorpDE0Cyk1h8Meta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/mirror.vue?macro=true";
import { default as pronoun8fv2kJRv4KMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesiP8l7PY5KpMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/sources.vue?macro=true";
import { default as team4UQIAMueCqMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/team.vue?macro=true";
import { default as terminologyLmWxNlApTUMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/terminology.vue?macro=true";
import { default as termsY3iP0l2AyeMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/terms.vue?macro=true";
import { default as userM312lrO61rMeta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/user.vue?macro=true";
import { default as workshopsLtDy3csNz9Meta } from "/home/admin/www/pronoms.fr/release/20241201123207/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsGiRK7MO89oMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansWeIOmAoPHbMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apickABJJq9IAMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93fqkoyYb5tJMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexkNIzVtewZcMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93WC6r9wo3ohMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93M0uXsvK6vEMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminus84JKX0qSMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexMlD2KfSZXpMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contacth2DSnJRD9fMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishC4czNVTT71Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqorTuxLzNRrMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusive9vCgD7dDqPMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academickEhI8uUteeMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexAa87AoYbL7Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaHjxkRvEL6EMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticscDwy1cUdoxMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinehqvxREabYfMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesqeMbxBvZ0GMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexR5ZXqoDDByMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesoWlIHmPAm7Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peoplehAgB9JRejiMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyM1GDZ2OxBPMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93Ny77RPWno4Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93F5wQz8waz4Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editoroIqc621UzXMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyJH1Akfv5GAMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askTRH3VoaE0jMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingCqQePN5OUYMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexAcj7S13kfqMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorpDE0Cyk1h8Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronoun8fv2kJRv4KMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesiP8l7PY5KpMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: team4UQIAMueCqMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyLmWxNlApTUMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsY3iP0l2AyeMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userM312lrO61rMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsLtDy3csNz9Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241201123207/pages/workshops.vue")
  }
]